<template>
  <v-card class="pb-6"
    ><total-seats-warning-bar
      v-model="updateBanner"
      v-if="showSeatInfoWarning"
      v-show="!inviteSent"
      id="inviteSeatWarning"
      sendInternalEmail
    />
    <div class="px-3">
      <v-row
        v-if="!inviteSent"
        :class="showUpgradeBox ? '' : 'mb-6'"
        class="pt-3"
      >
        <v-col
          class="ma-0 pa-0"
          v-for="tab in tabs"
          :key="tab.tabName"
          :data-cy="tab.tabName"
          cols="4"
        >
          <v-tooltip v-if="tab.toolTip === true" right color="transparent">
            <feature-flag-note
              v-if="tab.disabled"
              :message="tab.toolTipMessage"
            />

            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-btn
                  x-large
                  :class="currentTab === tab.tabName ? textColor : 'grey--text'"
                  tile
                  block
                  elevation="0"
                  @click="currentTab = tab.tabName"
                  :color="
                    currentTab === tab.tabName ? 'white' : 'grey lighten-2'
                  "
                  :disabled="tab.disabled"
                >
                  <v-icon v-if="tab.disabled">mdi-lock</v-icon
                  >{{
                    $vuetify.breakpoint.mdAndDown
                      ? tab.buttonSimplifiedText
                      : tab.buttonText
                  }}</v-btn
                ></span
              >
            </template>
          </v-tooltip>

          <v-btn
            v-else
            x-large
            :class="currentTab === tab.tabName ? textColor : 'grey--text'"
            tile
            block
            elevation="0"
            @click="currentTab = tab.tabName"
            :color="currentTab === tab.tabName ? 'white' : 'grey lighten-2'"
            :disabled="tab.disabled"
            ><v-icon v-if="tab.disabled">mdi-lock</v-icon
            >{{
              $vuetify.breakpoint.mdAndDown
                ? tab.buttonSimplifiedText
                : tab.buttonText
            }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row justify="center" v-if="showUpgradeBox">
        <upgrade-snackbar
          class="my-3"
          v-model="showUpgradeBox"
          :numberOfInvitesRemaining="invitesRemaining"
          :numberOfEmailsToSend="numberOfEmailsToSend"
          :status="currentUserTeamAndSubscriptionBasicInformation.accessTier"
        />
      </v-row>
      <div class="px-9" v-if="!inviteSent">
        <v-form v-if="currentTab === 'singleInvite'" v-model="valid" ref="form">
          <v-row justify="space-between" class="pt-6">
            <v-col
              cols="12"
              lg="6"
              class="pa-0"
              :class="$vuetify.breakpoint.lgAndUp ? 'pr-4' : ''"
            >
              <v-text-field
                class="data-hj-suppress"
                prepend-inner-icon="mdi-account-circle"
                :label="$t('textFields.name')"
                placeholder="Jane Doe"
                v-model="name"
                outlined
                height="68"
                :color="mainColor"
                data-cy="name"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="pa-0"
              :class="$vuetify.breakpoint.lgAndUp ? 'pl-4' : ''"
            >
              <v-text-field
                class="data-hj-suppress"
                prepend-inner-icon="email"
                :label="$t('textFields.email')"
                placeholder="janedoe@email.com"
                v-model="singleEmailAddress"
                :rules="[
                  rules.emailRequired($t('inputRules.emailExists')),
                  rules.emailValid($t('inputRules.emailFormat'), validEmail)
                ]"
                outlined
                height="68"
                :color="mainColor"
                data-cy="email"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="pa-0"
              :class="$vuetify.breakpoint.lgAndUp ? 'pr-4' : ''"
            >
              <v-combobox
                prepend-inner-icon="mdi-tag"
                :items="allTags"
                :label="$t('invite.dialog.tagLabel')"
                :placeholder="$t('invite.dialog.tagPlaceholder')"
                v-model="tag"
                outlined
                height="68"
                :color="mainColor"
                :hint="$t('invite.dialog.tagHint')"
                persistent-hint
              />
            </v-col>
          </v-row>
        </v-form>
        <v-row v-if="currentTab === 'bulkInvite'" justify="space-between">
          <v-col cols="12" class="pa-0 my-0 pt-6">
            <MultipleEmailsInputField v-model="emails" :color="mainColor" />
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pa-0"
            :class="$vuetify.breakpoint.lgAndUp ? 'pr-2' : ''"
          >
            <v-combobox
              prepend-inner-icon="mdi-tag"
              :items="allTags"
              :label="$t('invite.dialog.tagLabel')"
              :placeholder="$t('invite.dialog.tagPlaceholder')"
              v-model="tag"
              outlined
              height="68"
              :color="mainColor"
              :hint="$t('invite.dialog.tagHint')"
              persistent-hint
            />
          </v-col>
        </v-row>

        <v-row>
          <invite-via-file
            v-if="currentTab === 'fileInvite'"
            v-model="fileUploadEmails"
            :page="pageInFileUpload"
            @can-progress-event="canProgressInFileUpload = $event"
            @go-to-previous-page="pageInFileUpload -= 1"
          />
        </v-row>

        <v-row
          v-if="
            currentTab === 'singleInvite' ||
            currentTab === 'bulkInvite' ||
            pageInFileUpload === 2
          "
          class="mt-6"
          justify="space-between"
        >
          <v-col
            v-if="$vuetify.breakpoint.mdAndDown && currentTab !== 'fileInvite'"
            cols="12"
            class="pa-0"
          >
            <shareable-link-button :assessmentType="assessmentType" />
          </v-col>
          <v-col cols="12" lg="7" class="pa-0">
            <p class="text-h5 vitrueGrey--text mb-0">
              {{ $t("invite.dialog.personalisedMessageTitle") }}
              <v-btn icon @click="copyEmailTextToClipboard">
                <v-icon color="#A9A8A8">mdi-content-copy</v-icon>
              </v-btn>
            </p>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.lgAndUp && currentTab !== 'fileInvite'"
            cols="5"
            class="pa-0 text-end"
          >
            <shareable-link-button :assessmentType="assessmentType" />
          </v-col>
          <v-snackbar
            v-model="copyEmailSuccessful"
            absolute
            centered
            color="secondary"
            timeout="1500"
            transition="fade-transition"
            content-class="text-center"
            >{{ $t("invite.dialog.copySuccess") }}</v-snackbar
          >
          <v-snackbar
            v-model="copyEmailFailed"
            absolute
            centered
            color="error"
            timeout="1500"
            transition="fade-transition"
            content-class="text-center"
            >{{ $t("invite.dialog.copyError") }}</v-snackbar
          >
          <v-col cols="12" class="pa-0 pt-2">
            <v-textarea
              ref="messageArea"
              outlined
              label
              v-model="message"
              auto-grow
              no-resize
              class="text-area-height-limit"
              :color="mainColor"
              @change="customizedMessage = true"
            />
          </v-col>
        </v-row>
      </div>

      <div v-if="!inviteSent">
        <v-row class="mt-3" justify="center">
          <v-col
            cols="12"
            v-if="false"
            class="text-center text-h6"
            :class="textColor"
          >
            {{ newTotalUserText }}
          </v-col>
          <v-col cols="8" md="4" lg="3"
            ><v-btn
              :color="mainColor"
              rounded
              width="100%"
              outlined
              @click="$emit('input', false)"
              >{{ $t("buttons.cancel") }}</v-btn
            ></v-col
          >

          <v-col
            v-if="
              currentTab === 'singleInvite' ||
              currentTab === 'bulkInvite' ||
              pageInFileUpload === 2
            "
            cols="8"
            md="4"
            lg="3"
          >
            <v-tooltip right color="transparent">
              <feature-flag-note
                v-if="inviteDisabled && !sentButtonClicked"
                :message="inviteDisabledTooltip"
              />

              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    :color="mainColor"
                    rounded
                    width="100%"
                    @click="sendInvite()"
                    :disabled="inviteDisabled"
                    data-cy="invite"
                    >{{ $t("buttons.invite") }}</v-btn
                  ></span
                >
              </template>
            </v-tooltip>
          </v-col>

          <v-col
            v-if="currentTab === 'fileInvite' && pageInFileUpload !== 2"
            cols="8"
            md="4"
            lg="3"
          >
            <v-tooltip right color="transparent">
              <feature-flag-note
                v-if="!canProgressInFileUpload"
                :message="
                  pageInFileUpload === 0
                    ? $t('tooltips.uploadFile')
                    : $t('tooltips.selectEmail')
                "
              />

              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    :color="mainColor"
                    rounded
                    width="100%"
                    @click="pageInFileUpload += 1"
                    :disabled="!canProgressInFileUpload"
                    >{{ $t("buttons.next") }}</v-btn
                  ></span
                >
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row justify="center" class="pt-5" v-if="displaySendingEmailsBar">
          <p>
            {{
              $t("invite.dialog.sendingMessage", {
                "0": currentSendingIndex,
                "1": numberOfEmailsToSend
              })
            }}
          </p>
          <v-progress-linear color="success" indeterminate rounded height="6" />
        </v-row>
      </div>

      <v-container v-if="inviteSent">
        <InviteSentDesign
          :sent="sent"
          :failed="failed"
          :assessmentType="assessmentType"
        />
        <v-row justify="center" class="mt-5">
          <v-btn
            rounded
            :color="mainColor"
            min-width="200px"
            @click="$emit('input', false)"
            data-cy="finish"
            >{{ $t("buttons.finish") }}</v-btn
          >
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import {
  inviteUser,
  getTeamEndUsersEmails,
  checkFeatureIntroComplete
} from "@/customApi";
import { mapMutations, mapGetters } from "vuex";
import InviteSentDesign from "./InviteSentDesign.vue";
import FeatureFlagNote from "@/components/common/FeatureFlagNote.vue";
import UpgradeSnackbar from "./UpgradeSnackbar.vue";
import MultipleEmailsInputField from "./MultipleEmailsInputField.vue";
import InviteViaFile from "./InviteViaFile.vue";
import ShareableLinkButton from "./ShareableLinkButton.vue";
import { updateCheckListFromFeatures } from "@/services/onboarding/adminOnboarding";
import TotalSeatsWarningBar from "@/components/dashboard/TotalSeatsWarningBar.vue";

export default {
  components: {
    InviteSentDesign,
    FeatureFlagNote,
    UpgradeSnackbar,
    MultipleEmailsInputField,
    InviteViaFile,
    ShareableLinkButton,
    TotalSeatsWarningBar
  },
  props: {
    teamTags: Array,
    assessmentType: String
  },
  data() {
    return {
      tabs: [],
      currentTab: "singleInvite",
      bulk: false,
      tag: null,
      sent: [],
      failed: [],
      emails: [],
      singleEmailAddress: "",
      fileUploadEmails: [],
      currentEndUsers: [],
      name: "",
      showUpgradeBox: false,
      remainingInvitations: 0,
      displaySendingEmailsMessage: false,
      currentSendingIndex: 0,
      sentButtonClicked: false,
      valid: false,
      rules: {
        emailRequired(m) {
          return v => !!v || m;
        },
        emailValid(m, f) {
          return v => f(v) || m;
        }
      },
      copyEmailSuccessful: false,
      copyEmailFailed: false,
      pageInFileUpload: 0,
      canProgressInFileUpload: false,
      message: "",
      customizedMessage: false,
      updateBanner: false
    };
  },
  async mounted() {
    this.showUpgradeBox =
      (this.isTrialUser && this.invitesRemaining <= 3) ||
      this.trialExpiredOrAssessmentsExceeded ||
      this.subscriptionHasExpired;

    this.tabs = [
      {
        tabName: "singleInvite",
        buttonText: this.$t("invite.dialog.tabs.one"),
        buttonSimplifiedText: this.$t("invite.dialog.tabs.oneSimple"),
        disabled: false,
        toolTip: false,
        toolTipMessage: ""
      },
      {
        tabName: "bulkInvite",
        buttonText: this.$t("invite.dialog.tabs.many"),
        buttonSimplifiedText: this.$t("invite.dialog.tabs.manySimple"),
        disabled: false,
        toolTip: false,
        toolTipMessage: ""
      },
      {
        tabName: "fileInvite",
        buttonText: this.$t("invite.dialog.tabs.file"),
        buttonSimplifiedText: this.$t("invite.dialog.tabs.fileSimple"),
        disabled: false,
        toolTip: true,
        toolTipMessage: this.$t("tooltips.fileInvite")
      }
    ];
    let teamName = this.currentUserTeamAndSubscriptionBasicInformation.name;
    let team = teamName ? teamName : this.$t("invite.dialog.yourCompany");
    this.message =
      this.assessmentType === "Desk assessment"
        ? this.$t("invite.dialog.deskAssessmentMessage", { 0: team })
        : this.$t("invite.dialog.burnoutMessage");

    this.currentEndUsers = getTeamEndUsersEmails();
  },
  methods: {
    ...mapMutations(["setFeatureIntros"]),
    async sendInvite() {
      this.$refs.form?.validate();
      if (!this.valid && this.currentTab === "singleInvite") {
        return;
      }
      // Delay introduced because of race condition between combobox's vmodel and clicking the invite button
      // if you type into combo, don't hit return and click invite the button is executed before the emails are updated.
      this.sentButtonClicked = true;
      await this.delay(20);
      this.currentSendingIndex = 0;
      this.displaySendingEmailsMessage = true;

      if (this.currentTab === "fileInvite") {
        for (let i = 0; i < this.fileUploadEmails.length; i++) {
          let userToInvite = this.fileUploadEmails[i];
          let sanitisedTags = userToInvite.tag
            ? this.splitOutTags(userToInvite.tag)
            : [];
          await this.sendEmail(
            userToInvite.fullName,
            userToInvite.email,
            sanitisedTags
          );
        }
      } else if (this.currentTab === "singleInvite") {
        await this.sendEmail(
          this.name,
          this.singleEmailAddress,
          this.tag ? [this.tag] : []
        );
      } else {
        for (let i = 0; i < this.emails.length; i++) {
          await this.sendEmail(
            this.name,
            this.emails[i],
            this.tag ? [this.tag] : []
          );
        }
      }

      this.updateBanner = true;

      try {
        if (this.showOnboardingChecklist) {
          let updatedFeatures = await checkFeatureIntroComplete(
            this.onboardingChecklist.id
          );
          this.setFeatureIntros(updatedFeatures);
          updateCheckListFromFeatures(updatedFeatures);
        }
      } catch (err) {}
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async sendEmail(name, email, tag) {
      let currentUrl = window.location.href;
      let baseUrl = currentUrl.split("#")[0];

      if (this.assessmentType === "Desk assessment") {
        baseUrl += "#/deskassessment/";
      } else if (this.assessmentType === "Burnout assessment") {
        baseUrl += "#/burnoutassessment/";
      }

      // required because of bulk email sending
      if (!this.validEmail(email)) {
        this.currentSendingIndex++;
        this.failed.push(email);
        return;
      }
      let invitation = {
        name: name,
        email: { value: email.trim() },
        tags: tag,
        baseUrl: baseUrl,
        message: this.customizedMessage ? this.message : "",
        assessmentType: this.assessmentType
      };
      try {
        await inviteUser(this._i18n.locale, invitation);
        this.currentSendingIndex++;
        this.sent.push(email);
      } catch (err) {
        this.$logger.captureException(err);
        this.currentSendingIndex++;
        this.failed.push(email);
      }
    },
    validEmail(item) {
      return /.+@.+\..+/.test(item);
    },
    copyEmailTextToClipboard() {
      var component = this;
      navigator.clipboard.writeText(this.message).then(
        function () {
          component.copyEmailSuccessful = true;
        },
        function () {
          component.copyEmailFailed = true;
        }
      );
    },
    showUpgradeBoxOnEmailInputChange() {
      if (
        this.trialExpiredOrAssessmentsExceeded ||
        this.subscriptionHasExpired
      ) {
        this.showUpgradeBox = true;
        return;
      }
      if (this.isTrialUser) {
        if (this.numberOfEmailsToSend > this.invitesRemaining) {
          this.showUpgradeBox = true;
        } else {
          this.showUpgradeBox = this.invitesRemaining <= 3;
        }
        return;
      }

      this.showUpgradeBox = false;
    },
    splitOutTags(tags) {
      return String(tags)
        .split(",")
        .map(x => x.trim());
    }
  },
  watch: {
    currentTab() {
      this.emails = [];
      this.fileUploadEmails = [];
      this.sent = [];
      this.failed = [];
      this.name = "";
      this.tag = null;
      this.valid = false;
      this.pageInFileUpload = 0;
      this.canProgressInFileUpload = false;
      this.sentButtonClicked = false;
      this.showUpgradeBox =
        (this.isTrialUser && this.invitesRemaining <= 3) ||
        this.trialExpiredOrAssessmentsExceeded ||
        this.subscriptionHasExpired;
      this.singleEmailAddress = "";
    },
    inviteSent() {
      this.showUpgradeBox = false;
    },
    emails: {
      handler: function () {
        this.showUpgradeBoxOnEmailInputChange();
      },
      deep: true
    },
    fileUploadEmails: {
      handler: function () {
        this.showUpgradeBoxOnEmailInputChange();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters([
      "currentUserTeamAndSubscriptionBasicInformation",
      "onboardingChecklist",
      "showOnboardingChecklist",
      "trialExpiredOrAssessmentsExceeded",
      "subscriptionHasExpired",
      "showSeatInfoWarning"
    ]),
    mainColor() {
      return this.assessmentType === "Desk assessment" ? "primary" : "accent";
    },
    textColor() {
      return this.assessmentType === "Desk assessment"
        ? "primary--text"
        : "accent--text";
    },
    newTotalUserText() {
      if (this.currentTab === "singleInvite") {
        return this.$t("invite.dialog.totalUsersSingle", {
          0: this.totalUsersCount
        });
      }

      return this.$t("invite.dialog.totalUsers", {
        0: this.userCountDifference,
        1: this.totalUsersCount
      });
    },
    isTrialUser() {
      return (
        this.currentUserTeamAndSubscriptionBasicInformation.accessTier == "Free"
      );
    },
    results() {
      return this.sent.concat(this.failed);
    },
    inviteSent() {
      return (
        this.currentSendingIndex === this.numberOfEmailsToSend &&
        this.sentButtonClicked
      );
    },
    displaySendingEmailsBar() {
      const allEmailsSent =
        this.currentSendingIndex === this.numberOfEmailsToSend;
      return this.displaySendingEmailsMessage && !allEmailsSent;
    },
    numberOfEmailsToSend() {
      if (this.currentTab === "fileInvite") {
        return this.fileUploadEmails.length;
      }

      if (this.currentTab === "singleInvite") {
        if (this.singleEmailAddress !== "") {
          return 1;
        }
      }

      return this.emails.length;
    },
    totalUsersCount() {
      var totalUsers = [...this.currentEndUsers];
      if (this.currentTab === "singleInvite" && this.singleEmailAddress) {
        totalUsers.push(this.singleEmailAddress);
      } else if (this.currentTab === "fileInvite") {
        this.fileUploadEmails.forEach(e => {
          totalUsers.push(e[1]);
        });
      } else {
        totalUsers = totalUsers.concat(this.emails);
      }
      return totalUsers.filter(function (curItem, index, array) {
        return index == array.indexOf(curItem);
      }).length;
    },
    userCountDifference() {
      var totalExistingUsers = [...this.currentEndUsers];
      return this.totalUsersCount - totalExistingUsers.length;
    },
    invitesRemaining() {
      const emailsAllowedBySubscription =
        this.currentUserTeamAndSubscriptionBasicInformation
          .freeTrialNumberOfAssessments;
      const emailsSentSoFar =
        this.currentUserTeamAndSubscriptionBasicInformation
          .usedAssessmentInvitationsCount;

      return emailsAllowedBySubscription - emailsSentSoFar;
    },
    allTags() {
      return this.teamTags.filter(x => x.expiredAt === null).map(x => x.name);
    },
    inviteDisabled() {
      if (
        this.sentButtonClicked ||
        this.trialExpiredOrAssessmentsExceeded ||
        this.subscriptionHasExpired
      ) {
        return true;
      }

      if (this.isTrialUser) {
        return (
          this.invitesRemaining <= 0 ||
          this.numberOfEmailsToSend > this.invitesRemaining
        );
      }

      return false;
    },
    inviteDisabledTooltip() {
      if (
        this.trialExpiredOrAssessmentsExceeded ||
        this.subscriptionHasExpired
      ) {
        return this.$t("tooltips.trialOrSubscriptionExpired");
      }

      if (this.isTrialUser) {
        if (this.invitesRemaining <= 0) {
          return this.$t("tooltips.freeTrialNoMoreInvites");
        }

        if (this.numberOfEmailsToSend > this.invitesRemaining) {
          return this.$t("tooltips.freeTrialNotEnoughInvites");
        }
      }

      return "";
    }
  }
};
</script>

<style scoped>
.text-area-height-limit {
  max-height: 350px;
  overflow-y: auto;
}
</style>
