var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _c("v-img", {
            attrs: {
              src: require("@/assets/images/mailbox.svg"),
              "max-height": "25%",
              "max-width": "25%"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "d-flex flex-column align-center" }, [
        _c(
          "p",
          {
            staticClass: "pt-6 text-h4 primary--text",
            attrs: { "data-cy": "title" }
          },
          [_vm._v(_vm._s(_vm.title))]
        ),
        _c("p", { staticClass: "pt-2 text-body1" }, [
          _vm._v(" " + _vm._s(_vm.message) + " ")
        ])
      ]),
      _c(
        "v-row",
        { staticClass: "d-flex", attrs: { justify: "center" } },
        [
          _c(
            "v-list",
            [
              _vm._l(_vm.sent, function(email) {
                return _c(
                  "v-list-item",
                  { key: email },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("v-icon", { attrs: { color: "success" } }, [
                          _vm._v("check_circle")
                        ])
                      ],
                      1
                    ),
                    _c("v-list-item-title", [_vm._v(_vm._s(email))])
                  ],
                  1
                )
              }),
              _vm._l(_vm.failed, function(email) {
                return _c(
                  "v-list-item",
                  { key: email },
                  [
                    _c(
                      "v-list-item-icon",
                      [
                        _c("v-icon", { attrs: { color: "error" } }, [
                          _vm._v("close")
                        ])
                      ],
                      1
                    ),
                    _c("v-list-item-title", [_vm._v(_vm._s(email))])
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }