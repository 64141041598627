var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pb-6" },
    [
      _vm.showSeatInfoWarning
        ? _c("total-seats-warning-bar", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.inviteSent,
                expression: "!inviteSent"
              }
            ],
            attrs: { id: "inviteSeatWarning", sendInternalEmail: "" },
            model: {
              value: _vm.updateBanner,
              callback: function($$v) {
                _vm.updateBanner = $$v
              },
              expression: "updateBanner"
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "px-3" },
        [
          !_vm.inviteSent
            ? _c(
                "v-row",
                {
                  staticClass: "pt-3",
                  class: _vm.showUpgradeBox ? "" : "mb-6"
                },
                _vm._l(_vm.tabs, function(tab) {
                  return _c(
                    "v-col",
                    {
                      key: tab.tabName,
                      staticClass: "ma-0 pa-0",
                      attrs: { "data-cy": tab.tabName, cols: "4" }
                    },
                    [
                      tab.toolTip === true
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { right: "", color: "transparent" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "span",
                                          _vm._g({}, on),
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                class:
                                                  _vm.currentTab === tab.tabName
                                                    ? _vm.textColor
                                                    : "grey--text",
                                                attrs: {
                                                  "x-large": "",
                                                  tile: "",
                                                  block: "",
                                                  elevation: "0",
                                                  color:
                                                    _vm.currentTab ===
                                                    tab.tabName
                                                      ? "white"
                                                      : "grey lighten-2",
                                                  disabled: tab.disabled
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.currentTab = tab.tabName
                                                  }
                                                }
                                              },
                                              [
                                                tab.disabled
                                                  ? _c("v-icon", [
                                                      _vm._v("mdi-lock")
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$vuetify.breakpoint
                                                      .mdAndDown
                                                      ? tab.buttonSimplifiedText
                                                      : tab.buttonText
                                                  )
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              tab.disabled
                                ? _c("feature-flag-note", {
                                    attrs: { message: tab.toolTipMessage }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _c(
                            "v-btn",
                            {
                              class:
                                _vm.currentTab === tab.tabName
                                  ? _vm.textColor
                                  : "grey--text",
                              attrs: {
                                "x-large": "",
                                tile: "",
                                block: "",
                                elevation: "0",
                                color:
                                  _vm.currentTab === tab.tabName
                                    ? "white"
                                    : "grey lighten-2",
                                disabled: tab.disabled
                              },
                              on: {
                                click: function($event) {
                                  _vm.currentTab = tab.tabName
                                }
                              }
                            },
                            [
                              tab.disabled
                                ? _c("v-icon", [_vm._v("mdi-lock")])
                                : _vm._e(),
                              _vm._v(
                                _vm._s(
                                  _vm.$vuetify.breakpoint.mdAndDown
                                    ? tab.buttonSimplifiedText
                                    : tab.buttonText
                                )
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.showUpgradeBox
            ? _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c("upgrade-snackbar", {
                    staticClass: "my-3",
                    attrs: {
                      numberOfInvitesRemaining: _vm.invitesRemaining,
                      numberOfEmailsToSend: _vm.numberOfEmailsToSend,
                      status:
                        _vm.currentUserTeamAndSubscriptionBasicInformation
                          .accessTier
                    },
                    model: {
                      value: _vm.showUpgradeBox,
                      callback: function($$v) {
                        _vm.showUpgradeBox = $$v
                      },
                      expression: "showUpgradeBox"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.inviteSent
            ? _c(
                "div",
                { staticClass: "px-9" },
                [
                  _vm.currentTab === "singleInvite"
                    ? _c(
                        "v-form",
                        {
                          ref: "form",
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "pt-6",
                              attrs: { justify: "space-between" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  class: _vm.$vuetify.breakpoint.lgAndUp
                                    ? "pr-4"
                                    : "",
                                  attrs: { cols: "12", lg: "6" }
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "data-hj-suppress",
                                    attrs: {
                                      "prepend-inner-icon":
                                        "mdi-account-circle",
                                      label: _vm.$t("textFields.name"),
                                      placeholder: "Jane Doe",
                                      outlined: "",
                                      height: "68",
                                      color: _vm.mainColor,
                                      "data-cy": "name"
                                    },
                                    model: {
                                      value: _vm.name,
                                      callback: function($$v) {
                                        _vm.name = $$v
                                      },
                                      expression: "name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  class: _vm.$vuetify.breakpoint.lgAndUp
                                    ? "pl-4"
                                    : "",
                                  attrs: { cols: "12", lg: "6" }
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "data-hj-suppress",
                                    attrs: {
                                      "prepend-inner-icon": "email",
                                      label: _vm.$t("textFields.email"),
                                      placeholder: "janedoe@email.com",
                                      rules: [
                                        _vm.rules.emailRequired(
                                          _vm.$t("inputRules.emailExists")
                                        ),
                                        _vm.rules.emailValid(
                                          _vm.$t("inputRules.emailFormat"),
                                          _vm.validEmail
                                        )
                                      ],
                                      outlined: "",
                                      height: "68",
                                      color: _vm.mainColor,
                                      "data-cy": "email"
                                    },
                                    model: {
                                      value: _vm.singleEmailAddress,
                                      callback: function($$v) {
                                        _vm.singleEmailAddress = $$v
                                      },
                                      expression: "singleEmailAddress"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0",
                                  class: _vm.$vuetify.breakpoint.lgAndUp
                                    ? "pr-4"
                                    : "",
                                  attrs: { cols: "12", lg: "6" }
                                },
                                [
                                  _c("v-combobox", {
                                    attrs: {
                                      "prepend-inner-icon": "mdi-tag",
                                      items: _vm.allTags,
                                      label: _vm.$t("invite.dialog.tagLabel"),
                                      placeholder: _vm.$t(
                                        "invite.dialog.tagPlaceholder"
                                      ),
                                      outlined: "",
                                      height: "68",
                                      color: _vm.mainColor,
                                      hint: _vm.$t("invite.dialog.tagHint"),
                                      "persistent-hint": ""
                                    },
                                    model: {
                                      value: _vm.tag,
                                      callback: function($$v) {
                                        _vm.tag = $$v
                                      },
                                      expression: "tag"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab === "bulkInvite"
                    ? _c(
                        "v-row",
                        { attrs: { justify: "space-between" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 my-0 pt-6",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("MultipleEmailsInputField", {
                                attrs: { color: _vm.mainColor },
                                model: {
                                  value: _vm.emails,
                                  callback: function($$v) {
                                    _vm.emails = $$v
                                  },
                                  expression: "emails"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              class: _vm.$vuetify.breakpoint.lgAndUp
                                ? "pr-2"
                                : "",
                              attrs: { cols: "12", md: "6" }
                            },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  "prepend-inner-icon": "mdi-tag",
                                  items: _vm.allTags,
                                  label: _vm.$t("invite.dialog.tagLabel"),
                                  placeholder: _vm.$t(
                                    "invite.dialog.tagPlaceholder"
                                  ),
                                  outlined: "",
                                  height: "68",
                                  color: _vm.mainColor,
                                  hint: _vm.$t("invite.dialog.tagHint"),
                                  "persistent-hint": ""
                                },
                                model: {
                                  value: _vm.tag,
                                  callback: function($$v) {
                                    _vm.tag = $$v
                                  },
                                  expression: "tag"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _vm.currentTab === "fileInvite"
                        ? _c("invite-via-file", {
                            attrs: { page: _vm.pageInFileUpload },
                            on: {
                              "can-progress-event": function($event) {
                                _vm.canProgressInFileUpload = $event
                              },
                              "go-to-previous-page": function($event) {
                                _vm.pageInFileUpload -= 1
                              }
                            },
                            model: {
                              value: _vm.fileUploadEmails,
                              callback: function($$v) {
                                _vm.fileUploadEmails = $$v
                              },
                              expression: "fileUploadEmails"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.currentTab === "singleInvite" ||
                  _vm.currentTab === "bulkInvite" ||
                  _vm.pageInFileUpload === 2
                    ? _c(
                        "v-row",
                        {
                          staticClass: "mt-6",
                          attrs: { justify: "space-between" }
                        },
                        [
                          _vm.$vuetify.breakpoint.mdAndDown &&
                          _vm.currentTab !== "fileInvite"
                            ? _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c("shareable-link-button", {
                                    attrs: {
                                      assessmentType: _vm.assessmentType
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "12", lg: "7" }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "text-h5 vitrueGrey--text mb-0"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "invite.dialog.personalisedMessageTitle"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: _vm.copyEmailTextToClipboard
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "#A9A8A8" } },
                                        [_vm._v("mdi-content-copy")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm.$vuetify.breakpoint.lgAndUp &&
                          _vm.currentTab !== "fileInvite"
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 text-end",
                                  attrs: { cols: "5" }
                                },
                                [
                                  _c("shareable-link-button", {
                                    attrs: {
                                      assessmentType: _vm.assessmentType
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-snackbar",
                            {
                              attrs: {
                                absolute: "",
                                centered: "",
                                color: "secondary",
                                timeout: "1500",
                                transition: "fade-transition",
                                "content-class": "text-center"
                              },
                              model: {
                                value: _vm.copyEmailSuccessful,
                                callback: function($$v) {
                                  _vm.copyEmailSuccessful = $$v
                                },
                                expression: "copyEmailSuccessful"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("invite.dialog.copySuccess"))
                              )
                            ]
                          ),
                          _c(
                            "v-snackbar",
                            {
                              attrs: {
                                absolute: "",
                                centered: "",
                                color: "error",
                                timeout: "1500",
                                transition: "fade-transition",
                                "content-class": "text-center"
                              },
                              model: {
                                value: _vm.copyEmailFailed,
                                callback: function($$v) {
                                  _vm.copyEmailFailed = $$v
                                },
                                expression: "copyEmailFailed"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("invite.dialog.copyError")))]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pa-0 pt-2", attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                ref: "messageArea",
                                staticClass: "text-area-height-limit",
                                attrs: {
                                  outlined: "",
                                  label: "",
                                  "auto-grow": "",
                                  "no-resize": "",
                                  color: _vm.mainColor
                                },
                                on: {
                                  change: function($event) {
                                    _vm.customizedMessage = true
                                  }
                                },
                                model: {
                                  value: _vm.message,
                                  callback: function($$v) {
                                    _vm.message = $$v
                                  },
                                  expression: "message"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          !_vm.inviteSent
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-3", attrs: { justify: "center" } },
                    [
                      false
                        ? _c(
                            "v-col",
                            {
                              staticClass: "text-center text-h6",
                              class: _vm.textColor,
                              attrs: { cols: "12" }
                            },
                            [_vm._v(" " + _vm._s(_vm.newTotalUserText) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { attrs: { cols: "8", md: "4", lg: "3" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: _vm.mainColor,
                                rounded: "",
                                width: "100%",
                                outlined: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("input", false)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
                          )
                        ],
                        1
                      ),
                      _vm.currentTab === "singleInvite" ||
                      _vm.currentTab === "bulkInvite" ||
                      _vm.pageInFileUpload === 2
                        ? _c(
                            "v-col",
                            { attrs: { cols: "8", md: "4", lg: "3" } },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { right: "", color: "transparent" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "span",
                                              _vm._g({}, on),
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: _vm.mainColor,
                                                      rounded: "",
                                                      width: "100%",
                                                      disabled:
                                                        _vm.inviteDisabled,
                                                      "data-cy": "invite"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.sendInvite()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("buttons.invite")
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1630352098
                                  )
                                },
                                [
                                  _vm.inviteDisabled && !_vm.sentButtonClicked
                                    ? _c("feature-flag-note", {
                                        attrs: {
                                          message: _vm.inviteDisabledTooltip
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.currentTab === "fileInvite" &&
                      _vm.pageInFileUpload !== 2
                        ? _c(
                            "v-col",
                            { attrs: { cols: "8", md: "4", lg: "3" } },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { right: "", color: "transparent" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "span",
                                              _vm._g({}, on),
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: _vm.mainColor,
                                                      rounded: "",
                                                      width: "100%",
                                                      disabled: !_vm.canProgressInFileUpload
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.pageInFileUpload += 1
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("buttons.next")
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3999599831
                                  )
                                },
                                [
                                  !_vm.canProgressInFileUpload
                                    ? _c("feature-flag-note", {
                                        attrs: {
                                          message:
                                            _vm.pageInFileUpload === 0
                                              ? _vm.$t("tooltips.uploadFile")
                                              : _vm.$t("tooltips.selectEmail")
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.displaySendingEmailsBar
                    ? _c(
                        "v-row",
                        { staticClass: "pt-5", attrs: { justify: "center" } },
                        [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("invite.dialog.sendingMessage", {
                                    "0": _vm.currentSendingIndex,
                                    "1": _vm.numberOfEmailsToSend
                                  })
                                ) +
                                " "
                            )
                          ]),
                          _c("v-progress-linear", {
                            attrs: {
                              color: "success",
                              indeterminate: "",
                              rounded: "",
                              height: "6"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.inviteSent
            ? _c(
                "v-container",
                [
                  _c("InviteSentDesign", {
                    attrs: {
                      sent: _vm.sent,
                      failed: _vm.failed,
                      assessmentType: _vm.assessmentType
                    }
                  }),
                  _c(
                    "v-row",
                    { staticClass: "mt-5", attrs: { justify: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            rounded: "",
                            color: _vm.mainColor,
                            "min-width": "200px",
                            "data-cy": "finish"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("input", false)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("buttons.finish")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }