var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-sheet",
    {
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var onSheet = ref.onSheet
            var attrsSheet = ref.attrsSheet
            return [
              _c(
                "v-tooltip",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        top: "",
                        disabled: _vm.allowShareableLink,
                        color: "transparent"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "div",
                                  _vm._g(_vm._b({}, "div", attrs, false), on),
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          "x-small": "",
                                          rounded: "",
                                          color: "accent",
                                          "data-cy": "shareableLinkButton",
                                          disabled: !_vm.allowShareableLink
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.sheet = true
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              left: "",
                                              dark: ""
                                            }
                                          },
                                          [_vm._v("mdi-link-variant")]
                                        ),
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("buttons.shareableLink")
                                          ) + " "
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    "v-tooltip",
                    attrsSheet,
                    false
                  ),
                  onSheet
                ),
                [
                  _c("vitrue-tooltip", {
                    attrs: {
                      message: _vm.$t(
                        "invite.shareableLink.disabledDescription"
                      )
                    }
                  })
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.sheet,
        callback: function($$v) {
          _vm.sheet = $$v
        },
        expression: "sheet"
      }
    },
    [
      _c(
        "v-sheet",
        { staticClass: "text-center", attrs: { height: "200px" } },
        [
          _c("div", { staticClass: "text-h4 primary--text py-3" }, [
            _vm._v(" " + _vm._s(_vm.$t("invite.shareableLink.title")) + " ")
          ]),
          _c("router-link", {
            attrs: { to: _vm.link },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var href = ref.href
                  return [
                    _c("a", { attrs: { href: href } }, [
                      _vm._v(_vm._s(_vm.baseUrl + href))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("div", [
            _vm._v(
              " " + _vm._s(_vm.$t("invite.shareableLink.description")) + " "
            )
          ]),
          _c(
            "v-btn",
            {
              staticClass: "mt-6",
              attrs: { outlined: "", color: "secondary" },
              on: {
                click: function($event) {
                  _vm.sheet = !_vm.sheet
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("buttons.close")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }