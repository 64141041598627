var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-combobox", {
    ref: "comboBox",
    attrs: {
      "prepend-inner-icon": "email",
      label: _vm.$t("invite.multipleEmailInput.label"),
      placeholder: "leela@planetexpress.com, shane@vitruehealth.com",
      multiple: "",
      clearable: "",
      chips: "",
      hint: _vm.$t("invite.multipleEmailInput.hint"),
      outlined: "",
      color: _vm.color,
      "data-cy": "emails"
    },
    on: { keyup: _vm.processEmails, paste: _vm.processEmails },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function(ref) {
          var attrs = ref.attrs
          var item = ref.item
          var parent = ref.parent
          var selected = ref.selected
          return [
            _c(
              "v-chip",
              _vm._b(
                {
                  key: JSON.stringify(item),
                  staticClass: "data-hj-suppress",
                  attrs: {
                    "input-value": selected,
                    color: _vm.validEmail(item) ? "gray lighten-2" : "error",
                    "text-color": _vm.validEmail(item) ? "black" : "error",
                    outlined: !_vm.validEmail(item),
                    close: ""
                  },
                  on: {
                    "click:close": function($event) {
                      return _vm.removeEmail(item)
                    }
                  }
                },
                "v-chip",
                attrs,
                false
              ),
              [
                _vm.validEmail(item)
                  ? _c("v-avatar", {
                      staticClass: "primary white--text",
                      attrs: { left: "" },
                      domProps: {
                        textContent: _vm._s(item.slice(0, 1).toUpperCase())
                      }
                    })
                  : _vm._e(),
                _vm._v(" " + _vm._s(item) + " ")
              ],
              1
            )
          ]
        }
      }
    ]),
    model: {
      value: _vm.comboBoxModel,
      callback: function($$v) {
        _vm.comboBoxModel = $$v
      },
      expression: "comboBoxModel"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }