<template>
  <div>
    <v-row justify="center" align="center">
      <v-img
        src="@/assets/images/mailbox.svg"
        max-height="25%"
        max-width="25%"
      ></v-img>
    </v-row>
    <div class="d-flex flex-column align-center">
      <p class="pt-6 text-h4 primary--text" data-cy="title">{{ title }}</p>
      <p class="pt-2 text-body1">
        {{ message }}
      </p>
    </div>
    <v-row class="d-flex" justify="center">
      <v-list>
        <v-list-item v-for="email in sent" :key="email">
          <v-list-item-icon>
            <v-icon color="success">check_circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ email }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-for="email in failed" :key="email">
          <v-list-item-icon>
            <v-icon color="error">close</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ email }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "InviteSentDesign",
  props: {
    sent: Array,
    failed: Array,
    assessmentType: String
  },
  computed: {
    title() {
      if (this.sent.length > 0 && this.failed.length === 0) {
        return this.isDeskAssessment
          ? this.$t("invite.sent.deskAssessment.successTitle")
          : this.$t("invite.sent.burnout.successTitle");
      }

      if (this.sent.length === 0 && this.failed.length > 0) {
        return this.isDeskAssessment
          ? this.$t("invite.sent.deskAssessment.errorTitle")
          : this.$t("invite.sent.burnout.errorTitle");
      }

      return this.isDeskAssessment
        ? this.$t("invite.sent.deskAssessment.unsentTitle")
        : this.$t("invite.sent.burnout.unsentTitle");
    },
    message() {
      if (this.sent.length > 0 && this.failed.length === 0) {
        return this.isDeskAssessment
          ? this.$t("invite.sent.deskAssessment.successMessage")
          : this.$t("invite.sent.burnout.successMessage");
      }

      if (this.sent.length === 0 && this.failed.length > 0) {
        return this.isDeskAssessment
          ? this.$t("invite.sent.deskAssessment.errorMessage")
          : this.$t("invite.sent.burnout.errorMessage");
      }

      return this.isDeskAssessment
        ? this.$t("invite.sent.deskAssessment.unsentMessage")
        : this.$t("invite.sent.burnout.unsentMessage");
    },
    isDeskAssessment() {
      return this.assessmentType === "Desk assessment";
    }
  }
};
</script>
