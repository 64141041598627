<template>
  <v-bottom-sheet v-model="sheet">
    <template v-slot:activator="{ onSheet, attrsSheet }">
      <v-tooltip
        v-bind="attrsSheet"
        v-on="onSheet"
        top
        :disabled="allowShareableLink"
        color="transparent"
      >
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-btn
              x-small
              rounded
              color="accent"
              data-cy="shareableLinkButton"
              :disabled="!allowShareableLink"
              @click="sheet = true"
            >
              <v-icon x-small left dark>mdi-link-variant</v-icon
              >{{ $t("buttons.shareableLink") }}
            </v-btn>
          </div>
        </template>
        <vitrue-tooltip
          :message="$t(`invite.shareableLink.disabledDescription`)"
        />
      </v-tooltip>
    </template>
    <v-sheet class="text-center" height="200px">
      <div class="text-h4 primary--text py-3">
        {{ $t("invite.shareableLink.title") }}
      </div>
      <router-link :to="link" v-slot="{ href }">
        <a :href="href">{{ baseUrl + href }}</a>
      </router-link>
      <div>
        {{ $t("invite.shareableLink.description") }}
      </div>
      <v-btn class="mt-6" outlined color="secondary" @click="sheet = !sheet">{{
        $t("buttons.close")
      }}</v-btn>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { userTeamMemberInformation, getTeamSettings } from "@/customApi";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
export default {
  components: {
    VitrueTooltip
  },
  props: {
    assessmentType: String
  },
  data() {
    return {
      sheet: false,
      teamId: null,
      teamIdpName: null,
      allowShareableLink: false
    };
  },
  async mounted() {
    let teamMemberInformation = await userTeamMemberInformation();
    this.teamId = teamMemberInformation.teamId;
    this.teamIdpName = teamMemberInformation.teamIDP;
    let teamSettings = await getTeamSettings();
    this.allowShareableLink = teamSettings.allowShareableLink;
  },
  computed: {
    baseUrl() {
      let currentUrl = window.location.href;
      return currentUrl.split("#")[0];
    },
    link() {
      if (this.teamIdpName) {
        return {
          name: "Login",
          query: {
            teamid: this.teamId,
            idp: this.teamIdpName,
            assessmenttype: this.assessmentType
          }
        };
      } else {
        return {
          name: "SignUp",
          query: {
            autojointeamid: this.teamId,
            assessmenttype: this.assessmentType
          }
        };
      }
    }
  }
};
</script>
