var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "white--text px-4 py-2",
      attrs: { "min-width": "250", "min-height": "30", color: "red" }
    },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { icon: "", small: "", color: "white" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("input", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("clear")])],
                1
              ),
              _vm.messageToDisplay != ""
                ? _c("span", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.messageToDisplay))
                  ])
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { text: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        name: "Subscription",
                        params: {
                          tabToShow: "SUBSCRIPTION",
                          showDialogOnLoad: true
                        }
                      })
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      this.subscriptionDue
                        ? _vm.$t("buttons.payNow")
                        : _vm.$t("buttons.upgradeSimple")
                    )
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }